$(document).ready(function () {
  // ご利用者様の声
  let maxHeight = 0;
  $(".js-voice").each(function(){
    const height = $(this).height();
    if (maxHeight < height) {
      maxHeight = height;
    }
  })
  $(".js-voice").height(maxHeight)

  // 検索
  const waitTime = 300;
  let searchTimer;
  let lastValue = "";

  $("#js-request-search-open").on("click", function(){
    $("#js-request-search-q-field").val("")
    $("#js-request-search-results").empty().hide()
    lastValue = ""
    MicroModal.show("js-search-modal")
  })

  $("#js-request-search-q-field").on("keyup", function(){
    const currentValue = $(this).val()
    const type = $("#js-request-search-type-field").val()
    const purchase = $("#js-request-search-purchase-field").val()
    const listing = $("#js-request-search-listing-field").val()
    const url = $(this).data("type") === "purchase" ? "/request/products-for-purchase" : "/request/products-for-listing"

    if (lastValue != currentValue) {
      clearTimeout(searchTimer);

      if (currentValue.length > 0){
        searchTimer = setTimeout(function () {
          $.ajax(url, {
            type: "get",
            data: { q: currentValue, type: type, purchase: purchase, listing: listing, time: (new Date()).getTime() },
            dataType: "script",
          })
        }, waitTime)
      } else {
        $("#js-request-search-results").empty().hide()
      }

      lastValue = currentValue;
    }
  })

  $('#js-request-search-results').on('scroll', function() {
    const scrollHeight = $(this).prop('scrollHeight')
    const scrollTop = $(this).scrollTop()
    const containerHeight = $(this).height()

    if (scrollHeight - (scrollTop + containerHeight) <= 20) {
      if ( !$(this).data("scrolled") ) {
        $(this).data("scrolled", true)

        setTimeout(function() {
          Rails.fire($("#js-request-search-next-page")[0], "submit")
        }, 350);
      }
    }
  })

  // 職業選択
  const checkJob = function() {
    if ($("#js-purchase-request-job-select-field").val() == "その他"){
      $("#js-purchase-request-job-text-field").prop("disabled", false).fadeIn()
    } else {
      $("#js-purchase-request-job-text-field").hide().val("").prop("disabled", true)
    }
    $("#js-purchase-request-job-hidden-field").val($("#js-purchase-request-job-select-field").val())
  }
  $("#js-purchase-request-job-select-field").on("change", checkJob)
  checkJob()

  // 適格証明書発行事業者番号選択
  const checkInvoice = function() {
    if ($(".js-invoice-radio-button:checked").val() == "1") {
      $("#js-invoice-number-text-field input").prop("disabled", false)
      $("#js-invoice-number-text-field").fadeIn()
    } else {
      $("#js-invoice-number-text-field").hide()
      $("#js-invoice-number-text-field input").val("").prop("disabled", true)
    }
  }
  $(".js-invoice-radio-button").on("change", checkInvoice)
  checkInvoice()

  // 集荷日のパターン
  const togglePickupDate = function() {
    // 全体を一旦無効化
    $(".js-pickup-on fieldset").prop("disabled", true)
    $(".js-pickup-on fieldset").hide()
    $(".js-pickup-on").hide()

    if ($(".js-box-size-radio-button:checked").length == 0) {
      $(".js-pickup-on[data-box-selected='false']").show()
    } else {
      $(".js-pickup-on[data-box-selected='true']").show()

      if ($(".js-box-size-radio-button:checked").val() == "none") {
        $(".js-pickup-on fieldset[data-require-box='false']").prop("disabled", false)
        $(".js-pickup-on fieldset[data-require-box='false']").show()
      } else {
        $(".js-pickup-on fieldset[data-require-box='true']").prop("disabled", false)
        $(".js-pickup-on fieldset[data-require-box='true']").show()
      }
    }
  }
  $(".js-box-size-radio-button").on("change", togglePickupDate)
  togglePickupDate()
})
