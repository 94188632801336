$(document).ready(function() {
  $(".js-swiper").each(function(){
    const hash = $(this).data("hash")
    if(!$(this).attr("id")) {
      $(this).attr("id", `js-swiper-${hash}`)
    }
    $(this).find(".js-swiper-next").attr("id", `js-swiper-next-${hash}`)
    $(this).find(".js-swiper-prev").attr("id", `js-swiper-prev-${hash}`)

    const loop = $(this).is("[data-loop]")
    const centered = $(this).is("[data-centered]")
    const space = $(this).is("[data-no-space]") ? 0 : 20

    const swiper = new Swiper(`#${$(this).attr("id")}`, {
      loop: loop,
      centeredSlides: centered,
      slidesPerView: "auto",
      spaceBetween: space,
      navigation: {
        nextEl: `#js-swiper-next-${hash}`,
        prevEl: `#js-swiper-prev-${hash}`,
      },
    });
  })
})
